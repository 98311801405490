import '../../css/chat.css';
import Sidebar from './Sidebar'
import Box from './Box'

function Inscription() {

    const mymeetup = "my meetup name"

    return (

        <div className="main">
            <div className="section w3-flex-row w3-block">
                <Box mymeetup={mymeetup}/>
                <Sidebar mymeetup={mymeetup}/>
            </div>
        </div>

    );
}

export default Inscription;
