import '../../css/chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faQuestion } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';


function Sidebar(props) {

    const element = {
        name:'',
        meetupname:props.mymeetup,
        mail:'',
        faq:''

    }

    const ins = e => {
        e.preventDefault()
        axios
            .post('http://127.0.0.1:8000/Inscri',element)
            .then(res => {
                console.log(res)
            })
            .catch(error=>{
                console.log(error)
            })
    }

    const fn = () => {
        element.name=document.getElementById("fullName").value;
    }

    const ma = () => {
        element.mail=document.getElementById("mailAddress").value;
    }

    const qs = () => {
        element.faq=document.getElementById("question").value;
    }

    return (
        
        <div className="side-bar w3-flex-column w3-flex-1 w3-flex-center-h">

            <div className="w3-padding w3-block w3-big w3-text-white w3-xlarge">
                S'inscrire
            </div>
            <div className="w3-padding w3-padding-bottom-0 w3-padding-top-0 w3-display-container w3-margin">
                <input type="text" className="search w3-white w3-round-xxlarge w3-block w3-text-grey w3-medium" placeholder="Full name" id="fullName" onChange={fn} required/>
                <div className="search-icon w3-display-position w3-circle w3-pointer"><span className="text-linear w3-flex-center w3-text-white"><FontAwesomeIcon className="w3-margin-top-small" icon={faUser} /></span></div>
            </div>
            <div className="w3-padding w3-padding-bottom-0 w3-padding-top-0 w3-display-container w3-margin">
                <input type="text" className="search w3-white w3-round-xxlarge w3-block w3-text-grey w3-medium" placeholder="Mail adress" id="mailAddress" onChange={ma}/>
                <div className="search-icon w3-display-position w3-circle w3-pointer"><span className="text-linear w3-flex-center w3-text-white"><FontAwesomeIcon className="w3-margin-top-small" icon={faEnvelope} /></span></div>
            </div>
            <div className="w3-padding w3-padding-bottom-0 w3-padding-top-0 w3-display-container w3-margin">
                <input type="text" className="search w3-white w3-round-xxlarge w3-block w3-text-grey w3-medium" placeholder="Questions" id="question" onChange={qs} />
                <div className="search-icon w3-display-position w3-circle w3-pointer"><span className="text-linear w3-flex-center w3-text-white"><FontAwesomeIcon className="w3-margin-top-small" icon={faQuestion} /></span></div>
            </div>

            <div className="w3-margin">
                <span className="w3-round-xxlarge is-grey w3-padding w3-text-white w3-pointer" onClick={ins}>
                    S'inscrire
                </span>
            </div>            
        </div>
    );
}

export default Sidebar;
