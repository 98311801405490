import React from 'react';
import ReactDOM from 'react-dom';
import './css/w3.css'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './Components/Home/Home'
import Inscription from './Components/Inscription/Inscription'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Inscription} />
        <Route path="/Inscription" exact component={Inscription} />
      </Switch>
    </Router>
  );
}

export default App;

if (document.getElementById('app')) {
    ReactDOM.render(<App />, document.getElementById('app'));
}
