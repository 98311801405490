import '../../css/chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

function Box(props) {

    return (
        <div className="chat-section w3-overflow w3-flex-column w3-flex-3 w3-relative  w3-display-container">

            <div className="w3-height w3-display-middle w3-block w3-white"></div>
            <div className="w3-display-topmiddle w3-block is-grey w3-padding w3-flex-center-v ll"> 
                <FontAwesomeIcon className="w3-text-white w3-margin-right" icon={faHome}/>
                <FontAwesomeIcon className="w3-text-white w3-margin-right" icon={faQuestionCircle}/>
            </div>
            
            <div className="w3-height w3-display-middle">
                <div className="w3-flex-center w3-block w3-height w3-flex-1 w3-overflow-scroll">
                    <div className="w3-twothird w3-padding-32 w3-round-xlarge">
                        <div className="w3-block w3-flex-row w3-flex-center-h">
                            <div className="pdp-geant w3-margin w3-flex-center w3-big is-color-grey w3-xxlarge">
                                {props.mymeetup.toUpperCase()}
                            </div>
                            <div className="pdp-geant w3-margin w3-flex-center">
                                <img src="/img/is.png" alt="pdp" className="w3-height" />
                            </div>
                        </div>
                        <div className="w3-flex-center w3-flex-row">
                            <div className="welcome-box w3-flex-center w3-flex-1 w3-padding-16 w3-margin w3-flex-column w3-round-xlarge">
                                <div className="w3-big w3-large w3-text-white w3-margin">
                                    Easy meeting with anyone
                                </div>
                                <div className="w3-medium w3-text-white w3-margin-bottom w3-margin-left w3-margin-right">
                                    Share the invite with anyone even if they aren't on skype. No sign ups or downloads required
                                </div>
                                <div className="human-chats w3-pointer w3-twothird w3-padding-small is-grey w3-flex-1 w3-round-xxlarge w3-medium  w3-text-white w3-flex-center-h">
                                    Meet now
                                </div>
                            </div>
                            <div className="welcome-box w3-flex-center w3-flex-1 w3-padding-16 w3-margin w3-flex-column w3-round-xlarge">
                                <div className="w3-big w3-large w3-text-white w3-margin">
                                    Call mobiles and landlines
                                </div>
                                <div className="w3-medium w3-text-white w3-margin-bottom w3-margin-left w3-margin-right">
                                    Share the invite with anyone even if they aren't on skype. No sign ups or downloads required
                                </div>
                                <div className="human-chats w3-pointer w3-twothird w3-padding-small is-grey w3-flex-1 w3-round-xxlarge w3-medium  w3-text-white w3-flex-center-h">
                                    New call
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Box;
